/* eslint-disable no-unreachable */
import React, { FC } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { matchPath } from 'react-router-dom';

// ROUTER
import AppRoute from 'routes';
import AppDenticareRoute from 'routes/denticare';
import AppEInvoiceRoute from 'routes/einvoice';
import AppErrorRoute from 'routes/error';
import AppAppointmentRoute from 'routes/appointment';
import AppCheckInCheckOutRoute from 'routes/check-in-check-out';

// ERROR BOUNDARY
import ErrorBoundary from 'components/ErrorBoundary';
import ErrorView from 'components/ErrorView';

// Routes
import { Router } from 'constant';

// APP CONTAINER
import linkIdConfiguration from 'config/linkId';
import { NHThemeProvider } from 'ui-kit';
import { selectEnabledNewDenticare } from 'redux/featureFlag/selectors';
import { useAppSelector } from 'hooks';
import AppContainer from './App.container';
import AppDenticareContainer from './App.Denticare.container';
import AppEInvoiceContainer from './App.EInvoice.container';
import AppNoHeaderContainer from './App.NoHeader.container';
import AppAppointmentContainer from './App.Appointment.container';
import AppCheckInCheckOutContainer from './App.CheckInCheckOut.container';

const BaseContainer: FC = ({ children }) => (
  <NHThemeProvider>
    <Auth0Provider {...linkIdConfiguration}>
      <AppContainer>{children}</AppContainer>
    </Auth0Provider>
  </NHThemeProvider>
);

const App: FC<Record<string, unknown>> = () => {
  const isDenticareRoute = window.location.pathname.includes(Router.denticare);
  const isNewDenticareEnabled = useAppSelector(selectEnabledNewDenticare);
  const isEInvoiceRoute = window.location.pathname.includes(`${Router.eInvoice}/`);
  const isAppointmentRoute = window.location.pathname.includes(`${Router.appointment}/`);
  const isCheckInCheckOutRoute = window.location.pathname.includes(`${Router.checkInCheckOut}/`);
  const isPageNotFound = window.location.pathname.includes(`${Router.NotFoundPage}`);

  let isValidRoute = false;

  for (const route of Object.values(Router)) {
    if (matchPath(window.location.pathname, { path: route, exact: true })) {
      isValidRoute = true;
      break;
    }
  }

  if (!isValidRoute) {
    window.location.href = Router.NotFoundPage;

    return null;
  }

  // Incase Denticare is disabled, we should redirect to 404
  if (isValidRoute && isDenticareRoute && !isNewDenticareEnabled) {
    window.location.href = Router.NotFoundPage;
  }

  if (isPageNotFound) {
    return (
      <AppNoHeaderContainer>
        <AppErrorRoute />
      </AppNoHeaderContainer>
    );
  }

  if (isAppointmentRoute) {
    return (
      <ErrorBoundary>
        {(hasError) => {
          if (hasError) {
            return (
              <AppAppointmentContainer>
                <ErrorView />
              </AppAppointmentContainer>
            );
          }

          return (
            <AppAppointmentContainer>
              <AppAppointmentRoute />
            </AppAppointmentContainer>
          );
        }}
      </ErrorBoundary>
    );
  }

  if (isCheckInCheckOutRoute) {
    return (
      <ErrorBoundary>
        {(hasError) => {
          if (hasError) {
            return (
              <AppCheckInCheckOutContainer>
                <ErrorView />
              </AppCheckInCheckOutContainer>
            );
          }

          return (
            <AppCheckInCheckOutContainer>
              <AppCheckInCheckOutRoute />
            </AppCheckInCheckOutContainer>
          );
        }}
      </ErrorBoundary>
    );
  }

  if (isEInvoiceRoute) {
    return (
      <ErrorBoundary>
        {(hasError) => {
          if (hasError) {
            return (
              <AppEInvoiceContainer>
                <ErrorView />
              </AppEInvoiceContainer>
            );
          }

          return (
            <AppEInvoiceContainer>
              <AppEInvoiceRoute />
            </AppEInvoiceContainer>
          );
        }}
      </ErrorBoundary>
    );
  }

  if (isDenticareRoute && isNewDenticareEnabled) {
    return (
      <ErrorBoundary>
        {(hasError) => {
          if (hasError) {
            return (
              <AppDenticareContainer>
                <ErrorView />
              </AppDenticareContainer>
            );
          }

          return (
            <AppDenticareContainer>
              <AppDenticareRoute />
            </AppDenticareContainer>
          );
        }}
      </ErrorBoundary>
    );
  }

  return (
    <ErrorBoundary>
      {(hasError) => {
        if (hasError) {
          return (
            <BaseContainer>
              <ErrorView />
            </BaseContainer>
          );
        }

        return (
          <BaseContainer>
            <AppRoute />
          </BaseContainer>
        );
      }}
    </ErrorBoundary>
  );
};

export default App;
