export enum CheckInCheckOutEnrollmentStatus {
  ENROLLED = 'ENROLLED',
  NOT_ENROLLED = 'NOT_ENROLLED'
}

export enum CheckInCheckOutActivityType {
  INDIVIDUAL = 'INDIVIDUAL',
  ORGANIZATION = 'ORGANIZATION'
}

export enum CheckInCheckOutActionType {
  CHECK_IN = 'CHECK_IN',
  CHECK_OUT = 'CHECK_OUT'
}

export enum AttendanceStatus {
  NOT_ATTENDED = 'NOT_ATTENDED',
  ATTENDING = 'ATTENDING',
  ATTENDED = 'ATTENDED'
}

export enum CheckInCheckOutStep {
  NULL = 'NULL',
  VALIDATION_PROFILE = 'VALIDATION_PROFILE',
  RETRY_VALIDATION_PROFILE = 'RETRY_VALIDATION_PROFILE',
  TO_CHECK_IN = 'TO_CHECK_IN',
  TO_CHECK_OUT = 'TO_CHECK_OUT',
  CHECK_OUT_SUCCESS = 'CHECK_OUT_SUCCESS'
}

export interface IndividualValidationResponse {
  activityId: string;
  volunteerId: string;
  mobileNumber: string;
  activityName: string;
  centerName: string;
  activityStartTime: number;
  activityEndTime: number;
  activityType: CheckInCheckOutActivityType;
  enrollmentStatus: CheckInCheckOutEnrollmentStatus;
  attendanceStatus: AttendanceStatus;
  attendanceStatusTime?: number;
  volunteerName: string;
}

export interface IndividualCheckInCheckOutResponse {
  activityId: string;
  volunteerId: string;
  attendanceAction: CheckInCheckOutActionType;
  attendanceStatus: AttendanceStatus;
  attendanceStatusTime?: number;
  actionTime: number;
}

export interface CheckInCheckOutCenterState {
  loading: boolean;
  step: CheckInCheckOutStep;
  individualValidation?: IndividualValidationResponse;
  individualCheckInCheckOut?: IndividualCheckInCheckOutResponse;
  showSomethingWentWrong?: boolean;
  attendanceStatusTime?: number;
}
