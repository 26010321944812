import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

export const DenticareWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const EInvoiceWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const AppointmentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const CheckInCheckOutWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
`;

export const NoHeaderWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const WhiteTooltip = withStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'black',
    fontSize: 14,
    padding: 15,
    borderRadius: 8,
    border: `0px solid #62D2D5`,
    boxShadow: `0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)`
  }
}))(Tooltip);

export const WhiteTooltipCustomPosition = withStyles((theme) => ({
  arrow: {
    color: theme.palette.common.white
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'black',
    fontSize: 14,
    padding: 15,
    borderRadius: 8,
    border: `0px solid #62D2D5`,
    boxShadow: `0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)`,
    transform: 'translate3d(-1px, -15px, 0px) !important'
  }
}))(Tooltip);
