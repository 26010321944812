import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import {
  IndividualValidationResponse,
  IndividualCheckInCheckOutResponse,
  CheckInCheckOutCenterState,
  CheckInCheckOutStep
} from 'model';

export const changeCheckInCheckOutCenterLoading: CaseReducer<CheckInCheckOutCenterState, PayloadAction<boolean>> = (
  state,
  action
) => {
  state.loading = action.payload;
};

export const changeCheckInCheckOutCenterShowSomethingWentWrong: CaseReducer<
  CheckInCheckOutCenterState,
  PayloadAction<boolean>
> = (state, action) => {
  state.showSomethingWentWrong = action.payload;
};

export const setCheckInCheckOutStep: CaseReducer<CheckInCheckOutCenterState, PayloadAction<CheckInCheckOutStep>> = (
  state,
  action
) => {
  state.step = action.payload;
};

export const setAttendanceStatusTime: CaseReducer<CheckInCheckOutCenterState, PayloadAction<number | undefined>> = (
  state,
  action
) => {
  state.attendanceStatusTime = action.payload;
};

export const setIndividualValidationData: CaseReducer<
  CheckInCheckOutCenterState,
  PayloadAction<IndividualValidationResponse | undefined>
> = (state, action) => {
  state.individualValidation = action.payload;
};

export const setIndividualCheckInCheckOutData: CaseReducer<
  CheckInCheckOutCenterState,
  PayloadAction<IndividualCheckInCheckOutResponse | undefined>
> = (state, action) => {
  state.individualCheckInCheckOut = action.payload;
};
