import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

// MODEL
import {
  OrgValidationResponse,
  OrgCheckInCheckOutResponse,
  OrgCheckInCheckOutCenterState,
  CheckInCheckOutStep,
  CheckInCheckOutCenterState
} from 'model';

export const changeOrgCheckInCheckOutCenterLoading: CaseReducer<
  OrgCheckInCheckOutCenterState,
  PayloadAction<boolean>
> = (state, action) => {
  state.loading = action.payload;
};

export const changeOrgCheckInCheckOutCenterShowSomethingWentWrong: CaseReducer<
  OrgCheckInCheckOutCenterState,
  PayloadAction<boolean>
> = (state, action) => {
  state.showSomethingWentWrong = action.payload;
};

export const setOrgValidationData: CaseReducer<
  OrgCheckInCheckOutCenterState,
  PayloadAction<OrgValidationResponse | undefined>
> = (state, action) => {
  state.orgValidation = action.payload;
};

export const setCheckInCheckOutStep: CaseReducer<OrgCheckInCheckOutCenterState, PayloadAction<CheckInCheckOutStep>> = (
  state,
  action
) => {
  state.step = action.payload;
};

export const setAttendanceStatusTime: CaseReducer<CheckInCheckOutCenterState, PayloadAction<number | undefined>> = (
  state,
  action
) => {
  state.attendanceStatusTime = action.payload;
};

export const setOrgCheckInCheckOutData: CaseReducer<
  OrgCheckInCheckOutCenterState,
  PayloadAction<OrgCheckInCheckOutResponse | undefined>
> = (state, action) => {
  state.orgCheckInCheckOut = action.payload;
};
