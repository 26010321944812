import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { CheckInCheckOutCenterState, CheckInCheckOutStep } from 'model';

// REDUCER ACTION
import {
  changeCheckInCheckOutCenterLoading,
  changeCheckInCheckOutCenterShowSomethingWentWrong,
  setIndividualCheckInCheckOutData,
  setIndividualValidationData,
  setCheckInCheckOutStep,
  setAttendanceStatusTime
} from './reducers';

export const initialState: CheckInCheckOutCenterState = {
  loading: false,
  individualValidation: undefined,
  individualCheckInCheckOut: undefined,
  showSomethingWentWrong: false,
  attendanceStatusTime: undefined,
  step: CheckInCheckOutStep.NULL
};

export const checkInCheckOutCenterSlice = createSlice({
  name: Domain.CheckInCheckOutCenter,
  initialState,
  reducers: {
    changeCheckInCheckOutCenterLoading,
    changeCheckInCheckOutCenterShowSomethingWentWrong,
    setIndividualCheckInCheckOutData,
    setIndividualValidationData,
    setCheckInCheckOutStep,
    setAttendanceStatusTime
  }
});

export default checkInCheckOutCenterSlice.reducer;
