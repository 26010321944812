import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';

// ROUTE
import { Router } from 'constant';

// COMPONENT
import { baseLoader } from 'components/Loading';
import SomethingWentWrongPage from 'components/SomethingWentWrongPage';

const IndividualLandingPage = baseLoader(() => import('page/CheckInCheckOut/IndividualLandingPage'));
const OrgLandingPage = baseLoader(() => import('page/CheckInCheckOut/OrgLandingPage'));

const AppCheckInCheckOutRoute: FC = () => (
  <Switch>
    <Route exact component={SomethingWentWrongPage} path={Router.checkInCheckOutIndividualPageSomethingWrong} />
    <Route exact component={IndividualLandingPage} path={Router.checkInCheckOutIndividualWithActivityId} />
    <Route exact component={OrgLandingPage} path={Router.orgCheckInCheckOutWithActivityId} />
  </Switch>
);

export default AppCheckInCheckOutRoute;
