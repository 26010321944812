import { createSlice } from '@reduxjs/toolkit';

// DOMAIN
import { Domain } from 'constant';

// MODEL
import { OrgCheckInCheckOutCenterState, CheckInCheckOutStep } from 'model';

// REDUCER ACTION
import {
  changeOrgCheckInCheckOutCenterLoading,
  changeOrgCheckInCheckOutCenterShowSomethingWentWrong,
  setOrgCheckInCheckOutData,
  setOrgValidationData,
  setCheckInCheckOutStep,
  setAttendanceStatusTime
} from './reducers';

export const initialState: OrgCheckInCheckOutCenterState = {
  loading: false,
  orgValidation: undefined,
  orgCheckInCheckOut: undefined,
  showSomethingWentWrong: false,
  attendanceStatusTime: undefined,
  step: CheckInCheckOutStep.NULL
};

export const orgCheckInCheckOutCenterSlice = createSlice({
  name: Domain.OrgCheckInCheckOutCenter,
  initialState,
  reducers: {
    changeOrgCheckInCheckOutCenterLoading,
    changeOrgCheckInCheckOutCenterShowSomethingWentWrong,
    setOrgCheckInCheckOutData,
    setOrgValidationData,
    setCheckInCheckOutStep,
    setAttendanceStatusTime
  }
});

export default orgCheckInCheckOutCenterSlice.reducer;
