export enum Domain {
  App = 'app',
  AppTour = 'appTour',
  User = 'user',
  Contact = 'contact',
  Feedback = 'feedback',
  FeatureFlag = 'feature-flag',
  Address = 'address',
  DenticareBookAppointment = 'denticareBookAppointment',
  RegisterService = 'registerService',
  Cart = 'cart',
  Order = 'order',
  Service = 'service',
  Event = 'event',
  Sessions = 'sessions',
  Center = 'center',
  Offer = 'offer',
  InTake = 'intake',
  Card = 'card',
  ProductCatalog = 'ProductCatalog',
  ProductCatalogService = 'services',
  ProductCatalogServiceAlpha = 'services-alpha',
  ProductCatalogProgram = 'programs',
  ProductCatalogProgramAlpha = 'programs-alpha',
  ProductCatalogOffer = 'offers',
  ProductCatalogOfferAlpha = 'offers-alpha',
  DenticareClient = 'denticare-client',
  DenticareService = 'denticare-service',
  MedicalHistory = 'medical-history',
  FAQ = 'faq',
  EGiro = 'e-giro',
  EInvoice = 'e-invoice',
  Appointment = 'appt',
  CheckInCheckOutCenter = 'check-in-check-out',
  OrgCheckInCheckOutCenter = 'org-check-in-check-out'
}
