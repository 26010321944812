import styled from 'styled-components';
import { Grid } from 'ui-kit';

export const ErrorScreenWrapper = styled(Grid)`
  color: var(--gray-700, #344054);

  /* Text lg - 18/Regular */
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  color: #344054;

  .bg-gradient {
    min-height: 400px;
  }

  .err-img {
	width: 200px;
  }

  .err-title {
	color: var(--gray-900, #101828);

	/* Display xs - 24/Semibold */
	font-family: Inter;
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 32px; /* 133.333% */
  }

  .err-p {
	color: var(--gray-700, #344054);

/* Text lg - 18/Regular */
font-family: Inter;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: 28px; /* 155.556% */
  }

  .content-c {
	width: 100%:
  }

  .nav-bar {
    width: 100%;
    // padding: 0 100px;
    background: #ffffff;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    // flex-shrink: 0;
    border-bottom: 1px solid var(--gray-100, #eff1f5);
    background: var(--White, #fff);

    /* Shadow/sm */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
  }

  .nav-bar img {
    margin: 15px 0 10px;
  }

  .xl-placeholder {
    display: none;
  }

  .learn-more-btn {
    text-transform: none;
    text-decoration: underline;
    font-family: Inter;
    font-size: 16px;
    color: #344054;
    padding-top: 0px;
    font-weight: 400;
    cursor: pointer;
  }


  @media screen and (max-width: 768px) {
    .nav-bar {
      display: none;
    }

	.desdktop-layout {
		display: none;
	}
  }

  @media screen and (min-width: 1280px) {
    .xl-placeholder {
      display: flex;
    }
  }

  @media screen and (min-width: 768px) {
    .nav-bar {
      // padding: 0 20px;
    }
  }

  @media screen and (min-width: 992px) {
    .nav-bar {
      // padding: 0 60px;
    }
  }

  @media screen and (min-width: 1200px) {
    .nav-bar {
      // padding: 0 80px;
    }
  }

  @media screen and (min-width: 1226px) {
    width: 1008px;
  }
`;

export const MainErrorWrapper = styled(Grid)`
  flex: 1;
  display: flex;

  .err-btn {
    border-radius: 8px;
    border: 1px solid var(--gray-400, #98a2b3);
    background: var(--White, #fff);
    width: 100%;
  }

  .btn-back-nav {
    background: transparent;
  }

  .btn-back-nav: hover {
    background: transparent;
  }

  .err-btn-txt {
    color: var(--gray-900, #101828);

    /* Text md/Semibold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  .mobile-layout {
    display: none;
  }

  #err-img-mobile {
    display: none;
  }

  .err-nav-icon {
    -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
    filter: invert(100%);
    margin-right: 10px;
    height: 20px;
  }

  .err-nav-txt {
    color: var(--gray-800, #1d2939);

    /* Text md/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }

  .err-nav {
    background: var(--White, #fff);

    /* Shadow/md */
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
    padding: 4px 16px;
  }

  .err-btn-container {
    margin-top: 36px;
  }

  .text-content {
    margin-left: 25px;
  }

  .book-slot-btn {
    border-radius: 8px !important;
    border: 1px solid var(--primary-400, #62d2d5) !important;
    background: var(--primary-400, #62d2d5) !important;
  }

  @media screen and (max-width: 768px) {
    .desktop-layout {
      display: none !important;
    }

    .text-content {
      margin: 0;
    }

    .err-btn-container {
      margin-top: 16px;
    }

    .err-title {
      color: var(--gray-900, #101828);

      /* Text xl - 20/Semibold */
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 150% */
    }

    .err-p {
      color: var(--gray-700, #344054);

      /* Text md/Regular */
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }

    .mobile-layout {
      display: flex;
    }

    #err-img-mobile {
      display: block;
    }

    #err-img-desktop {
      display: none;
    }

    .err-btn {
      margin-bottom: 24px;
    }

    .err-img {
      height: 120px;
    }
  }
`;

export const MainErrorForMobileWrapper = styled(Grid)`
  flex: 1;
  display: flex;
  max-width: 500px;

  .err-btn {
    border-radius: 8px;
    border: 1px solid var(--gray-400, #98a2b3);
    background: var(--White, #fff);
    width: 100%;
  }

  .btn-back-nav {
    background: transparent;
  }

  .btn-back-nav: hover {
    background: transparent;
  }

  .err-btn-txt {
    color: var(--gray-900, #101828);

    /* Text md/Semibold */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }

  .mobile-layout {
    display: none;
  }

  #err-img-mobile {
    display: none;
  }

  .err-nav-icon {
    -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
    filter: invert(100%);
    margin-right: 10px;
    height: 20px;
  }

  .err-nav-txt {
    color: var(--gray-800, #1d2939);

    /* Text md/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }

  .err-nav {
    background: var(--White, #fff);

    /* Shadow/md */
    box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);
    padding: 4px 16px;
  }

  .err-btn-container {
    margin-top: 36px;
  }

  .text-content {
    margin-left: 25px;
  }

  .book-slot-btn {
    border-radius: 8px !important;
    border: 1px solid var(--primary-400, #62d2d5) !important;
    background: var(--primary-400, #62d2d5) !important;
  }

  .desktop-layout {
    display: none !important;
  }

  .text-content {
    margin: 0;
  }

  .err-btn-container {
    margin-top: 16px;
  }

  .err-title {
    color: var(--gray-900, #101828);

    /* Text xl - 20/Semibold */
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 150% */
  }

  .err-p {
    color: var(--gray-700, #344054);

    /* Text md/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 150% */
  }

  .mobile-layout {
    display: flex;
  }

  #err-img-mobile {
    display: block;
  }

  #err-img-desktop {
    display: none;
  }

  .err-btn {
    margin-bottom: 24px;
  }

  .err-img {
    height: 120px;
  }
`;
